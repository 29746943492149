$font-color: #111;
$font-light: #888;
$white: #FFF;


$red: #dc4735;
$yellow: #f6d96d;
$black: #231f20;
$orange: #eeae44;

$pale-yellow: #f7f7db;
$dark-blue: #1D3242;
$blue-green: #2D696C;
$askbill-green: #85AD33;

//old colours
$stormy-blue: #667D8C;
$stormy-blue-25: #D9DEE2;
$stormy-blue-60: #A3B1BA;
$muted-yellow: #F5E494;
$ice-blue: #EFF2F6;
$gray-blue: #819EA8;
$gray-blue-50: #C0CED3;
$cancel-gray: #DAE0E3;
$link-blue: #29ABE2;
$link-color: #29ABE2;
$dark-button-text: #222222;
$blue-opal: #daecf4;
$blue-opal-darker: #b9c7cf;
$flock-color: #09771c;
$ram-team-color: #5f1549;
$banner-blue: #13688c;
$panel-grey: #CDCFD0;

$grey1: #F5F5F5;
$grey2: #EEEEEE;
$grey3: #ADADAD;
$grey4: #929292;
$grey5: #6B6D70;
$grey6: #4A4A4A;
$grey7: #333333;
$btn-grey: #f6f6f6;
$hover-grey: #e8e8e8;
$c-dark-grey:#AAAAAA;
$c-light-grey:#F5F5F5;

$light-green: #44785e;
$dark-green: #224747;
$blue-new: #3e5066;

$error-red: #BC1717;





/**
Pie Chart Colours
*/
$colour-1: #00AEEF;
$colour-2: #D31920;
$colour-3: #92D050;
$colour-4: #F79646;
$colour-5: #25408F;
$colour-6: #FFD004;

$primary-action: $blue-new;
$primary-action-text: $white;
$primary-hover-text: $white;
$primary-hover-background: $light-green;


$secondary-action: $light-green;
$secondary-action-text: $white;
$secondary-hover-text: $white;
$secondary-hover-background: $dark-green;

$action-hover: $link-color;

//@import url(http://fonts.googleapis.com/css?family=Open+Sans+Condensed:700|Open+Sans:700,300,400);
