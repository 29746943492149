nav>ul>li>a {
  color: white;
  padding: 10px 15px 10px 15px;
  // height: 40px;
}

nav>ul>li>a:hover {
  text-decoration: none;
  background-color: black !important;
  color: white !important;
}

nav>ul>li>a:focus {
  background-color: black !important;
  color: white !important;
}

nav>ul {
  list-style-type: none;
}

nav>ul {
  padding-left: 0;
  margin-top: 15px;
  margin-bottom: 15px;
}

.dropdown>button {
  //  color: white;
  padding-top: 45px;
  padding-bottom: 15px;
  height: 80px;
  margin-right: 10px;
  width: max-content;
  //  border-radius: 4px;
}

.dropdown>button:hover {
  cursor: pointer;
  //background-color: $orange !important;
  text-decoration: underline;
}



.dropup,
.dropdown {
  position: relative;
  margin-top: 30px;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3em;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}

.no-arrow.dropdown-toggle {
  background: transparent !important;
  border: 0px;
  font-size: 16px;
  padding: 6px 0;

  &::after {
    border: 0px;
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  // display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: $font-color;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-divider {
  height: 1px;
  margin: 0.5rem 0;
  overflow: hidden;
  background-color: #eceeef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 3px 1.5rem;
  clear: both;
  font-weight: normal;
  color: #292b2c;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}


.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #636c72;
  cursor: not-allowed;
  background-color: transparent;
}

.show>.dropdown-menu {
  display: block;
}

.show>a {
  outline: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #636c72;
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 0.125rem;
}

// .controls {
//   width: 100%;
// }

// .dropdown-item {
//   color: lightgray;
// }

// .dropdown-item:hover {
//   color: white;
//   background-color: $dark-green;
// }

// .col-form-label {
//   font-weight: bold;
// }

// @media (min-width: 768px) {
//   .col-form-label {
//     text-align: right;
//   }
// }

// .jumbotron {
//   padding: 2rem;
//   background-color: $grey2;
// }

// .jumbotron-no-padding {
//   margin: 0;
//   padding-left: 0;
//   padding-right: 0;
// }

// @media (max-width: 767px) {
//   .jumbotron {
//     padding-left: 10px;
//     padding-right: 10px;
//   }

//   h1,
//   h2,
//   h3,
//   h4 {
//     padding-left: 0;
//     margin-left: 0;
//   }

//   button {
//     width: 100%;
//   }

// }

// .alert-warning {
//   background-color: #FFF6E8;
//   border-color: #F5A623;
// }

// .text-danger {
//   color: $error-red !important;
// }

// /*************bootstrap accordion***********/

// /* Step 1: Common Properties
// These styles are required to make icons render reliably */
// // .icon::before {
// //   display: inline-block;
// //   text-rendering: auto;
// //   -webkit-font-smoothing: antialiased;
// // }
// //
// //
// // .card-header::after {
// //   font-family: "Font Awesome 6 Free";
// //   font: var(--fa-font-solid);
// //   content: "\f063";
// //   float: right;
// // }
// //
// // .card-header.card-open::after {
// //   font-family: "Font Awesome 6 Free";
// //   font: var(--fa-font-solid);
// //   content: "\f060";
// //   float: right;
// // }

.card {
  border: 0;
}

.card-header {
  background-color: $grey2;
  border: 1px solid $grey4;
  border-radius: 0 !important;
  padding: 8px 10px;
}

.card-title {
  text-decoration: none;
  //cursor: pointer;
  font-weight: bold;
}

//.card-title:hover {
//  text-decoration: underline !important;
//}

.card-body {
  border: 1px solid $grey4;
  border-top: 0;
  padding: 10px;
}


// /*************end bootstrap accordion***********/

.nav-tabs {
  border-bottom: 1px solid $grey7 !important;
}

// .nav-tabs>li>a,
// .nav-tabs>li>a:focus,
// .nav-tabs>li>a:hover {
//   color: $font-color;
//   background-color: $grey2 !important;
//   border: 1px solid $c-dark-grey !important;
//   border-bottom-color: $grey7 !important;
//   ;
//   border-radius: 5px 5px 0 0 !important;
//   font-size: 18px;
//   font-weight: bold;
//   width: auto !important;
// }

// .nav-tabs>li.active>a {
//   background-color: $c-light-grey !important;
//   border-color: $grey7 !important;
//   border-bottom-color: $c-light-grey !important;
// }

// .nav-tabs>li>a:hover,
// .nav-tabs>li>a:active:focus,
// .nav-tabs>li.active>a:hover {
//   background-color: $secondary-action !important;
//   color: $secondary-action-text !important;
// }

.nav-tabs>li>a {
  color: $font-color;
  background-color: $grey2 !important;
  border: 1px solid $c-dark-grey !important;
  border-bottom-color: $grey7 !important;
  border-radius: 5px 5px 0 0 !important;
//  font-size: 18px;
  font-weight: bold;
  width: auto !important;
}

.nav-tabs>li>a:hover,
.nav-tabs > .nav-item > .nav-link.active {
  background-color: $primary-action !important;
  color: $primary-action-text !important;
}

div.tabView {
  background-color: $c-light-grey !important;
  border: 1px solid $grey7;
  border-top-color: transparent;
  height: unset !important;
  margin: 0 0 15px 0 !important;
  padding: 10px 0 10px 0;
  border-radius: 0 0 5px 5px !important;
}


.navbar-custom {
  padding: 0;
  //background-color: $dark-blue;

  //.navbar-brand {
  //  color: $navbar-light-brand-color;
  //
  //  @include hover-focus {
  //    color: $navbar-light-brand-hover-color;
  //  }
  //}

  .navbar-nav {
    .nav-link {
      padding: 10px 10px 5px 10px;
      //  min-width: 80px;
      text-align: center;
      font-weight: 300;
      font-size: 12pt;
      color: white;
      //  margin-top: 50px;
      margin-left: 8px;
      margin-right: 8px;
    //  height: 80px;
      //  font-family: 'Manjari', sans-serif;
      //  border-radius: 4px;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      &.disabled {
        color: $grey5;
        background-color: $grey2;

      }
    }

    // .show>.nav-link,
    // .active>.nav-link,
    // .nav-link.show,
    // .nav-link.active {
    //   color: white;
    //   background-color: transparent;
    // }
  }
}

// #signinlink {
//   width: 150px;
//   background-color: #eeae44;

//   &:hover,
//   &:focus {
//     background-color: $red !important;
//     //color: white !important;
//   }
// }

// #thefooter {
//   background-color: $black;
//   color: white;
// }

.dropdown-menu-custom {
  padding-bottom: 0;
  padding-top: 0;
  max-height: calc(100vh - 200px);
  overflow: auto;

  .dropdown-item {
    min-width: 200px;
    padding: 5px 20px;
    color: $font-color;
    background-color: white;

    &:hover,
    &:focus {
      background-color: $blue-new !important;
      color: white !important;
    }
  }

  .dropdown-divider {
    margin: 0;
  }
}

.container, .container-fluid {
  max-width: 1600px;
  padding-left: 15px !important;
  padding-right: 15px !important;
//  padding-left: 40px;
//  padding-right: 40px;
}

.form-control {
  font-size: inherit;
}

.pull-right {
  float: right;
}

.bg-primary-subtle {
  background-color: #e9ecef; // var(--bs-primary-bg-subtle) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: $grey2 !important; // rgba(248, 249, 250, 1) !important;
}

.form-label, .col-form-label {
  font-size: 16px;
  font-weight: bold;
}

.col-form-label {
  text-align: right;
}

.form-check {
  padding: 0;
}
