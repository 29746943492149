// Button Styling


.btn {
  border: none !important;
  width: fit-content;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0 !important;
}

.btn:disabled, .btn:disabled:hover {
  background-color: $grey3;
  color: $grey5;
}


.btn-primary, .k-primary {
  color: $primary-action-text !important;
  background-color: $primary-action !important;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 10px 6px 10px;
  box-shadow: none;
}

.btn-primary:hover, .btn-primary:focus, .k-primary:hover {
  color: $primary-hover-text !important;
  background-color: $primary-hover-background !important;
}

.btn-primary[disabled]{
  color: $grey5;
  background-color: $grey3;
}

.btn-default {
  color: $secondary-action-text;
  background-color: $dark-green;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 10px 6px 10px;
  box-shadow: none;

}

.btn-default:hover, .btn-default:focus{
  color: white;
  background-color: $primary-hover-background;
}

.btn-default[disabled]{
  color: $grey5;
  background-color: $grey3;
}

.btn-secondary, .btn-secondary:focus, .k-grid-cancel, .k-grid-cancel:focus{
  color: $secondary-action-text !important;
  background-color: $secondary-action !important;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 10px 6px 10px;
  box-shadow: none;
}

.btn-secondary:hover, .k-grid-cancel:hover{
  color: $secondary-hover-text !important;
  background-color: $secondary-hover-background !important;
}

.btn-secondary[disabled]{
  color: $grey5;
  background-color: $grey3;
}


.btn-transparent {
  color: $font-color !important;
  background-color: transparent;
  border: none;
}


.btn-home {
  //color: $primary-action-text;
//  background-color: $primary-action;
  font-weight: normal;
  font-size: 12pt;
//  font-family: 'Manjari', sans-serif;
  padding: 15px 15px 10px 15px;
}


// .btn:hover {
//   background-color: $primary-hover-background;
//   color: $primary-hover-text;
// }

.btn-cancel {
  background-color: $gray-blue;
  color: $cancel-gray;
}

.btn-cancel:hover {
  background-color: $dark-blue;
  color: white;
}

.btn-group-vertical > button {
  margin-bottom: 10px;
  border-radius: 10px !important;
}

.btn-simple {
  width: 100px;
}

.btn-no-side-padding {
  padding-left: 0;
  padding-right: 0;
}


.btn-stn-grid, .btn-stn-grid:focus{
  color: $primary-action-text !important;
  background-color: $black !important;
  padding: 6px 10px 6px 10px;
}

.btn-stn-grid:hover {
  color: $primary-hover-text;
  background-color: $primary-hover-background;
}

.btn-stn-grid[disabled]{
  color: $font-light;
  background-color: $grey3;
}

// .k-button, .k-button:hover {
//   background-image: unset;
// }
