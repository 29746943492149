/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';
// @import "bootstrap/dist/css/bootstrap.css";

//@import url(http://fonts.googleapis.com/css?family=Open+Sans+Condensed:700|Open+Sans:700,300,400);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap);
// $kendo-color-primary: #ff69b4;
// $kendo-grid-bg: #1bb822;

// @import "@progress/kendo-theme-default/scss/panelbar/_index.scss";
// @import "@progress/kendo-theme-default/scss/grid/_index.scss";

//html, body { height: 100%; }
//body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body { height: 100%; margin: 0; font-family: "Manrope", sans-serif; position: relative;}
