html,
body,
p {
  font-family: 'Manrope', sans-serif !important;
  //font-size: 14px;
  // font-weight: 300;
  font-size: 13pt !important;
  -webkit-overflow-scrolling: touch;
  color: $font-color;
}

h1,
h2,
h3,
h4 {
  margin-bottom: 20px;
  //  font-weight: 600;
  //font-family: 'Manjari', sans-serif;
  //  color: $blue-new;
}

h1 {
  font-size: 33pt !important;
  font-weight: bold !important;
}

h2 {
  font-size: 24pt !important;
  font-weight: bold !important;
}

h3 {
  font-size: 19pt !important;
  font-weight: bold !important;
  //  margin-bottom: 20px;
}

h4 {
  font-size: 15pt !important;
  font-weight: bold !important;
}

h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

fieldset {
  border: 0;
}

hr {
  //  margin-left: 50px;
  //  margin-right: 50px;
  border-top: 1px solid $black;
  margin-top: 20px;
  margin-bottom: 30px;
}

a {
  color: $text-color;

  &:focus {
    text-decoration: underline;
  }
}

input {
  font-size: 14px !important; //may prevent ios safari zoom
}

address {
  line-height: 1.5;
}

.extra-bold {
  text-shadow: 1px 0px 1px;
}

.extra-extra-bold {
  text-shadow: 2px 1px 1px $dark-blue;
}

.home-ul {
  margin-inline-start: -25px;

  li {
    font-size: 10px;
    line-height: 2;
  }

  li span {
    font-size: 14px;
  }
}

// i {
//   color: $yellow;
//
//   &:hover {
//     color: $white;
//   }
// }

.yellow-fa {
  color: $yellow;

  &:hover {
    color: $white;
  }
}

.montserrat-text {
  font-family: 'Montserrat', sans-serif;
}

.titillium-text {
  font-family: 'Titillium Web', sans-serif;
}

.static-content {
  padding-left: 30px;
  padding-right: 30px;
}

.smalltext {
  font-size: 12px;
  margin-bottom: 0;
}

#banner {
  padding: 0;
  position: relative;
  //  background: url("/images/banner.jpg") no-repeat;
  //  height: 613px;
  //  background-size: cover;

  #banner-text {
    margin: 0;
    //color: $white;
    position: absolute;
    // left: 50px;
    // bottom: 50%;
    left: 80px;
    top: 80%;
    transform: translate(0%, -80%);
  }
}

#detailbanner {
  background: url("/images/ATRIUM_detail.png") no-repeat;
  background-size: auto 100%;
  background-position: right;
}

#chartbanner {
  background: url("/images/chart-bg.jpg") no-repeat;
  height: 750px;
  background-size: cover;
  text-align: center;
  padding: 100px;

  #chartbanner-text {
    margin: 0;
    color: $white;
    position: absolute;
    // left: 50px;
    // bottom: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 850px;
  }
}


.grey-block {
  background-color: $grey1;
}

.orange-circle {
  width: 120px;
  height: 120px;
  background-color: $orange;
  border-radius: 50%;
  margin-bottom: 20px;
}


.hblock {
  padding: 40px;
  //  font-family: 'Montserrat', sans-serif;
  line-height: 1.3;
}



.contact-block {
  padding: 20px 50px;

  a {
    text-decoration: underline;
    color: $font-color;
  }

  p {
    margin-bottom: 0px;
    //font-family: 'Open Sans', sans-serif;
  }
}

.large-text {
  font-size: 20pt;
  font-weight: 300;
  //font-family: 'Titillium Web', sans-serif;
}

.larger-text {
  font-size: 23pt;
  font-weight: 500;
  //  font-family: 'Titillium Web', sans-serif;
}

.footer-img {
  height: 20px;
  margin: 5px;
}

//new angular container
.main {
  //margin-top: 15px;
  margin-top: 116.5px; //15 + fixed navbar height
}

.maincontent {
  position: absolute;
  //  left: 250px;
  left: 0px;
  //top: 120px;
  //margin-left: 10px;
  // padding-top: 10px;
  padding-bottom: 10px;
  //height: 100%;
  height: calc(100vh - 90px);
  // width: calc(100vw - 250px);
  width: 100vw;
  overflow: auto;
}

.maincontent2 {
  //  position: absolute;
  //  left: 250px;
  padding-top: 10px;
  //padding-bottom: 30px;
  //left: 0px;
  //  top: 130px;
  //margin-left: 10px;
  //  padding-top: 10px;
  //height: 100%;
  //  height: calc(100vh - 220px);
  // width: calc(100vw - 250px);
  width: 100%;
  //  overflow: auto;
}

.maincontent2>.container-fluid {
  padding: 0;
}

#thefooter {
  font-size: 12pt;
  //    height: 60px;
  //    width: 100%;
  //    position: absolute;
  //    bottom: 0;
  //    color: white;
  padding-left: 15px;
  padding-right: 15px;
}

.message-sent {
  text-align: center;
}

// .login-nav {
//     width: 180px;
// }

.footer-nav {
  //   float: right;
  //   margin-top: 25px;
  //  margin-right: 60px;
  //  margin-bottom: 15px;;

  li {
    display: inline;

    a {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  // a {
  //   color: #111111;
  // //  margin-right: 10px;
  // //  margin-left: 10px;
  // }
  // a:hover {
  //   color: #111111 !important;
  //   background-color: #DDDDDD !important;
  //   text-decoration: underline;
  // }
}

.form-group.has-error {
  color: $error-red; //bootstrap text-danger

  input {
    border: 1px solid $error-red;
    // border-color: $error-red;
  }
}


.form-group.ng-invalid {
  color: $error-red; //bootstrap text-danger

  input {
    border-color: $error-red;
  }
}

.form-error {
  color: $error-red;
}

.bold-label {
  font-weight: 500;
}

.ulink {
  text-decoration: underline !important;
  color: #007bbf;

  h4:hover {
    color: inherit;
  }
}

.no-pad-no-margin {
  padding: 0;
  margin: 0;
}

.no-padding {
  padding: 0;
}


.title {
  border: 1px solid $c-dark-grey;
  padding: 10px;
  margin: 24px 0;
}

.title-bar {
  background-color: $grey2;
  border: 1px solid $c-dark-grey;
  font-weight: 500;
  line-height: 16px;
  padding: 5px 10px;
  margin-bottom: 20px;
}

.title-bar-big {
  font-size: 18px;
}

.custom-panel-info.panel-info {
  border-color: $c-dark-grey;
  border-radius: 0;
}

.custom-panel-info.panel-info>.panel-heading {
  color: black;
  background-color: $grey2;
  border-color: $c-dark-grey;
}

.custom-panel-info.panel-info>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: $c-dark-grey;
}

.custom-panel-info.panel-info>.panel-collapse>.panel-body {
  padding: 10px;
}

.custom-panel-info.panel-info.no-padding>.panel-collapse>.panel-body {
  padding: 0;
}

.custom-panel-info.ampc-map.panel-info>.panel-collapse>.panel-body {
  padding: 0;
}

.custom-panel-info.panel-info>.panel-heading .badge {
  color: black;
  background-color: $grey2;
}

.custom-panel-info.panel-info>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: $c-dark-grey;
}

//.weight-chart {
//  width: 500px;
//  float: left;
//}

.map-container {
  margin-left: 20px;
  width: calc(100% - 520px);
  float: right;
}


.left-hand {
  margin: 0;
  width: 300px;
  float: left;
}

.right-hand {
  float: right;
}

@media (min-width: 320px) {
  .right-hand {
    margin: 0;
    width: unset;
  }

  .left-hand {
    width: 100%;
  }

  .container-fluid {
    padding: 0 10px;
  }
}

@media (min-width: 576px) {
  .right-hand {
    margin: 0 0 0 10px;
    width: calc(100% - 310px);
  }

  .left-hand {
    width: 300px;
  }
}

@media (min-width: 768px) {
  .right-hand {
    margin: 0 0 0 20px;
    width: calc(100% - 320px);
  }
}

@media (min-width: 993px) {
  .container-fluid {
    padding: 0 20px;
  }

}

@media (min-width: 1200px) {
  .right-hand {
    margin: 0 0 0 40px;
    width: calc(100% - 340px);
  }
}

/*-----------------------*/


::-webkit-input-placeholder {
  font-style: italic;
}

:-moz-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}


.text-error {
  color: #dc3545;
}

.centered-element {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100000;
}


.form-control:focus {
  border-color: $primary-action;
  box-shadow: none;
}

.row-nomargin {
  margin-left: 0;
  margin-right: 0;
}

.hlogo {
  width: 50%;
  margin-top: 40px;
  margin-bottom: 10px;
  max-width: 300px;
  min-width: 220px;
}

.profilepic {
  width: 100%;
  max-width: 260px;
  margin-bottom: 10px;
}

.flogo {
  //width: 80%;
  max-height: 80px;
  max-width: 100%;
  margin-bottom: 10px;
}

#retentionGrid tr.k-alt {
  background: transparent;
}

.db-amber {
  font-weight: 500 !important;
  //  background-color: #FFBF00;
  color: black;
  border-radius: 10px;
  background: rgba(255, 191, 0, 0.65);
}

.db-red {
  font-weight: 500 !important;
  //  background-color: #FD5151;
  color: black;
  border-radius: 10px;
  background: rgba(253, 0, 0, 0.65);
}

.db-green {
  font-weight: 500 !important;
  background: rgba(0, 216, 0, 0.65);
  //  background-color: #00d800;
  color: black;
  border-radius: 10px;
}

.baz {

  th,
  td {
    padding: 10px 10px 0px 0px;
  }

  td {
    vertical-align: top;
  }

  th {
    vertical-align: baseline;
  }
}

.strikeout {
  text-decoration: line-through;
}

div[data-notify="container"] {
  z-index: 20000 !important;
}

.stable {
  display: inline-block;
  //font-size: 15px;
  margin-bottom: 5px;

  th {
    font-weight: 500;
    text-align: center;
    min-width: 100px;
  }

  //  border: 1px solid #ddd;
  //  border-collapse: collapse;
  //    height: 60px;
  //  border-radius: 4px;

  th,
  td {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 1px solid #ddd;
    border-collapse: collapse;
  }

  td {
    text-align: center;
  }
}

.inline-hr {
  width: 25%;
  display: inline-block;
  vertical-align: middle;
  border-top: 3px solid $text-color;
  margin-left: 20px;
  margin-right: 20px;
}

.tiny {
  font-size: 9pt;
  font-weight: 300;
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 1251px) {
  .stable {
    font-size: 15px;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 1250px) {
  .stable {
    font-size: 13px;
  }
}


.chart-tooltip {
  padding: 4px 8px;
  font-size: 12px;
  background: white;
  border-radius: 2px;
  border: 1px solid lightgrey;
  position: relative;
  width: fit-content;
}

//faq accordions
#faqCards {
  & .card-header {

    & h5 {
      font-size: 1.2rem;

    }

  }

}

.h-link {
  &:focus {
    color: #0056b3;
    outline: none;
  }
}


summary {
  //strange _reboot.scss affecting component of same name
  display: block;
}

.hide-spinners {

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.k-upload .k-upload-files .k-file-validation-message {
  font-size: 13px;
  color: #BC1717;
  line-height: 18px !important;
}


// .navbar-custom .navbar-nav .show > .nav-link,
// .navbar-custom .navbar-nav .active > .nav-link,
// .navbar-custom .navbar-nav .nav-link.show,
// .navbar-custom .navbar-nav .nav-link.active {
//   color: #111;
//   background-color: white;
// }

.alert,
.alert div {
  border: none !important;
  background-color: transparent !important;
  color: #BC1717 !important;
  padding: 5px 0 !important;
  margin-bottom: 0;
}

.k-sort-order {
  display: none !important;
}

.page-header {
  background-color: #EEEEEE;
  border: 1px solid #AAAAAA;
  padding: 5px 10px;
  margin-bottom: 20px !important;
  line-height: 16px !important;
  font-size: 18px !important;
}

.page-header:hover {
  text-decoration: none !important;
}

.k-grid .k-column-title {
  white-space: normal;
}

.no-border {
  border: none !important;
}

.jumbo {
  padding: 2rem;
  background-color: #EEEEEE;
  border-radius: 4px;
  margin-top: 20px;
}

.k-grid-pager {
  min-height: 37px !important;
}

.k-dropzone-hint {
  font-style: italic !important;
}

.homeblock {
  padding: 60px;
}

.homeblock-sm {
  padding: 15px;
}

.largep {
  font-size: 20pt !important;
  line-height: 1.2;
}

hr {
  opacity: 1;
}

.h2s {
  margin-top: 20px !important;
  margin-bottom: 30px !important;
}

.casi-alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border: 1px solid#bee5eb;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
  margin-bottom: 2rem;
}

.rlabel {
  padding: 0;
}

.rcol {
  padding: 6.5px 2px;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 4px 15px 0px 0px !important;  //for help button to align with text
}

.accordion-button:not(.collapsed) {
  color: inherit;
  background-color: white;
}


//toggle switch

//.form-check-input:checked {
//  background-color: #92D050;
//  border-color: #92D050;
//}
//
//.form-check-input:focus {
//  border-color: transparent;
//}
.add-footer-margin {
  margin-bottom: 50px;
}


.k-table-th > .k-cell-inner {
align-items: normal;
}

.standard-font-size {
  & div, p, span, ul, li, strong, a {
    font-size: .8rem !important;
  }
}

.navbar-custom .navbar-nav .nav-link {
  font-size: 16pt !important;
}

div,
.k-grid td p, .k-grid .k-table-td p,
.k-grid td span, .k-grid .k-table-td span,
{
  font: 400 14px / 20px Roboto, sans-serif !important;
}
