.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: -4px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $c-dark-grey; // $font-light; // $font-color; // #2196F3;
    border-width: 1px;
    border-color: $font-color;
    border-style: solid;
   // background-color: #ccc;
    -webkit-transition: .3s;
    transition: .3s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    top: 1px;
    left: 1px;
    bottom: 4px;
    background-color: $grey2;
    border-style: solid;
    border-width: 1px;
    -webkit-transition: .3s;
    transition: .3s;
    box-sizing: border-box;
  }

  label.disabled {
    color: #aaa;
  }

  input:disabled {
    background: #dddddd;
  }

  input:disabled+label {
    color: #ccc;
  }

  input:checked + .slider {
    background-color: $c-dark-grey; // #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $c-dark-grey; // #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(31px);
    -ms-transform: translateX(31px);
    transform: translateX(31px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
