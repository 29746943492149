.slider-label{
  font-size: 12px;
}

.slider-line{
  stroke: #888888;
  stroke-width: 0.5px;
}

.ticks {
  font: 10px sans-serif;
}

.tick line, .axis--x path{
  stroke: none;
}

.track,
.track-inset,
.track-overlay {
  stroke-linecap: round;
}

.track {
  stroke: #000;
  stroke-opacity: 0.3;
  stroke-width: 10px;
}

.track-inset {
  stroke: #f9f7f7;
  stroke-width: 8px;
}

.track-overlay {
  pointer-events: stroke;
  stroke-width: 50px;
  stroke: transparent;
}

.handle {
}

.saved-handle{
  stroke: #929292;
  stroke-opacity: 0.5;
  stroke-width: 1.25px;
  fill: #C5DBBF;
}

.unsaved-handle{
  stroke:#929292;
  fill: white;
}

.weight-heading{
  border: .7px solid #adadad;
  float: left;
}

.target{
  stroke: #929292;
  stroke-opacity: 0.5;
  stroke-width: 1.25px;
  fill: #ffffff;
}

.rules line, .rules path {
  shape-rendering: crispEdges;
  stroke: #adadad;
  stroke-width: 0.2px;
}

.rules .tick {
}
.rules .minor {
  stroke: #BBB;
}
.rules .domain {
  fill: none;
}

.grid .tick {
  stroke: #CCC;
}


.series path {
  fill: none;
  stroke: #348;
  stroke-width: 1px;
}

.range{
  stroke: $primary-action;
  stroke-width: 7px;
}

.brush {
  fill: #777;
  fill-opacity: .125;
  shape-rendering: crispEdges;
  cursor: move;
}

.brush-line {
  fill: #7A7A7A;
  fill-opacity: 1;
  stroke: #7A7A7A;
  stroke-width: 2px;
}

.stacked-barchart-tooltip {
  position: absolute;
  text-align: left;
  border: 0.5px solid #dddddd;
  max-width: 100px;
  min-width: 100px;
  border-radius: 5px;
  padding: 5px;
  z-index: 10;
  color: #787878;
  background-color: white;
  font-size: 10px;
}
