
.k-window-content {
    padding-block: 16px;
    padding-inline: 16px;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background: none;
    outline: 0;
    overflow: auto;
    position: unset;
    flex: 1 1 auto;
}
