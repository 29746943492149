/* Kendo UI Styles*/
$base-theme: Default;
$skin-name: Environer;
$swatch-name: Default;
$border-radius: 2px;
$accent: #395161;
$info: #eeae44;
$success: #f6d96d;
$warning: #3a5f6f;
$error: #243757;
$text-color: #231f20;
$bg-color: #ffffff;
$base-text: #231f20;
$base-bg: #f6f6f6;
$hovered-text: #231f20;
$hovered-bg: #ededed;
$selected-text: #ffffff;
$selected-bg: #395161;
$series-a: #9c0000;
$series-b: #395161;
$series-c: #eeae44;
$series-d: #f6d96d;
$series-e: #dad5b7;
$series-f: #3a5f6f;
$series-g: #243757;
$series-h: #9396b8;

.k-scheduler {
  font-family: "DejaVu Sans", "Arial", sans-serif;
  font-size: .9em;
}

.k-label {
  //font-weight: normal;
  //margin-left: 2px;    font-weight: bold;
  text-align: right;
  margin: 0;
  padding: 0.375rem 15px; //0.375rem is what form-control has - to line up vertical
  font-weight: bold;
}

.k-form-inline fieldset {
  border: none !important;
}

.k-form-inline .k-form-field {
  margin-bottom: 15px !important;
}

// .k-filter-row th, .k-grid-header th.k-header {
//    font-weight: bold;
// }


/* Kendo UI Hide toolbar, navigation and footer during export */

.k-pdf-export .k-scheduler-toolbar,
.k-pdf-export .k-scheduler-navigation .k-nav-today,
.k-pdf-export .k-scheduler-navigation .k-nav-prev,
.k-pdf-export .k-scheduler-navigation .k-nav-next,
.k-pdf-export .k-scheduler-footer {
  display: none;
}

.k-loading-image {
  position: fixed;
  top: 10%;
  left: 10%;
  z-index: 100;
  display: none;
}


.k-filter-menu {
  max-width: 500px;
}

/*-----------WINDOW----------------*/

.k-window-title {
  font-weight: bold;
}

.k-window-titlebar.k-header {
  font-size: 22px;
  font-weight: bold;
  //  background-color: #F5F5F5;
  //  height: 20px;
  //  color: black;
  border: 0;
  //  padding-left: 0;
  //  padding-right: 0;
}

.k-dialog .k-dialog-titlebar .k-dialog-title {
  font-size: 22px !important;
}

.k-window-action.k-link.k-state-hover {
  background-image: none;
  background-color: transparent;
  border-color: transparent;
  font-size: 28px;
}

.k-window-action.k-link.k-state-hover>span.k-icon.k-i-close {
  right: 8px;
  font-size: 28px;
}


.k-window-action.k-link>span.k-icon.k-i-close {
  position: relative;
  right: 5px;
  color: black;
  font-size: 22px;
  font-weight: bold;
}

/*-----------BUTTON----------------*/
// .k-button {
//   color: $font-color;
//   background-color: white;
//   border-color: $font-color;
//   padding: 4px;
// }
//
// .k-button:hover {
//   color: #ffffff;
//   background-color: $primary-action;
//   border-color: $primary-action;
// }

/*-----------GRID----------------*/

.k-grid {
  color: #111;
}

.k-grid tr.k-state-selected:hover,
.k-grid td.k-state-selected:hover,
.k-grid tr:hover {
  background-color: $grey1 !important;
  background-image: none;
  color: $font-color;
}

.k-grid tr.k-state-selected,
.k-grid td.k-state-selected {
  background-color: $grey4 !important;
  background-image: none;
  color: $font-color;
}

.k-grid tr,
.k-grid .k-alt {
  background-color: white;
  color: $grey5;
}

.k-grid td {
  border-width: 0 0 1px 1px;
}

/*-----------GRID PAGER----------------*/

/*-----------GRID TOOLBAR----------------*/
.k-grid-toolbar {
  width: 100%;

  .k-button {
    float: right;
  }
}

.k-button .k-grid-toolbar {
  float: left;
}

.k-grid-toolbar {
  width: inherit;
}

#grid .k-grid-toolbar {
  padding: .6em 1.3em;
}

/*-----------GRID HEADER----------------*/
.k-grid .k-grid-header .k-header {
  white-space: normal; // Stop header text wrapping with size too small
}

/*----------CHECKBOX----------------*/
.k-checkbox {
  border-color: $selected-bg ;
  background-color: white;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: $selected-bg ;
  color: white;
  background-color: $selected-bg ;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
  box-shadow: 0 0 0 2px rgba(29, 131, 185, 0.3);
}

.k-checkbox-label:after {
  //  content:"\2713";
  //color: $font-color !important;
  font-weight: bold;
}

.k-checkbox-label:before,
.k-checkbox:focus+.k-checkbox-label:before {
  border-color: $grey4 !important;
}

.k-checkbox-label:hover:before,
.k-checkbox-label:hover:after,
.k-checkbox:hover+.k-checkbox-label:after {
  border-color: $font-color !important;
}

.k-checkbox:indeterminate+.k-checkbox-label:after {
  //  background-color: $font-color !important;
  border-color: $font-color !important;
}

/*----------RADIO VIEW----------------*/
.k-radio {
  border-color: $selected-bg ;
  border-top-color: $selected-bg ;
  border-right-color: $selected-bg ;
  border-bottom-color: $selected-bg ;
  border-left-color: $selected-bg ;
  background-color: $bg-color ;
  color: $bg-color ;
}

.k-radio:checked,
.k-radio.k-checked {
  border-color: $selected-bg ;
  color: $blue-new ;
  background-color: $blue-new ;
}

.k-radio:checked,
.k-radio.k-checked {
  background-image: none; // url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3e%3ccircle cx='50%25' cy='50%25' r='4' fill='white'/%3e%3c/svg%3e);
}

.k-radio:checked:focus,
.k-radio.k-checked.k-focus {
  box-shadow: 0 0 0 2px rgba(29, 131, 185, 0.3);
}

.k-radio:disabled,
.k-radio.k-disabled {
  outline: none;
  cursor: default;
  opacity: 0.5;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}

.k-radio-label:hover:before {
  border-color: $font-color !important;
}

.k-radio-label::before {
  border-color: unset;
}

.k-radio:checked+.k-radio-label::after {
  background-color: $blue-new;
}

.k-radio:checked:focus+.k-radio-label::before {
  box-shadow: none;
}

/*MULTISELECT BUTTONS*/
.k-multiselect-wrap .k-button .k-select {
  position: unset;
}

.k-multiselect-wrap .k-button .k-select .k-icon {
  right: 0;
  font-size: 16px;
}


.k-picker-wrap .k-select {
  padding: 0;
}

.k-confirm>.k-dialog-content {
  font-weight: bold;
}

.k-editor-dialog.k-editor-table-wizard-dialog.k-window-content {
  width: unset;
}

.k-editor-dialog.k-editor-table-wizard-dialog .k-tabstrip-wrapper .k-content {
  height: unset;
}

.k-insertHtml {
  width: 250px;
}

.k-imagebrowser .k-floatwrap>.k-breadcrumbs,
.k-imagebrowser .k-floatwrap>.k-search-wrap {
  display: none;
}

.k-filebrowser .k-floatwrap>.k-breadcrumbs,
.k-filebrowser .k-floatwrap>.k-search-wrap {
  display: none;
}

.k-tile strong {
  float: unset;
  width: unset;
}

.k-radio:disabled+.k-radio-label,
.k-checkbox:disabled+.k-checkbox-label {
  opacity: 0.4;
}

.k-item-title {
  overflow: auto;
  margin-left: 10px;
}

.k-popup-edit-form {
  min-width: 500px;
  padding-right: 18px !important;
  padding-bottom: 30px !important;
}

.k-multicheck-wrap {
  white-space: normal;
}

.k-grid .k-tooltip.k-tooltip-validation {
  position: fixed;
  margin-top: 35px !important;
  margin-left: 0 !important;
}

.k-i-warning:before {
  position: unset;
}

span.k-input-inner {
  white-space: normal;
}

.k-grid td,
.k-grid .k-table-td {
  vertical-align: top;
}

.k-dropzone-hint {
  overflow: unset !important;
}

.k-avatar-solid-primary {
   border-color: transparent;
   color: white;
  background-color: transparent;
}

.k-label-empty {
  padding: 2px;
}

.k-dialog-wrapper {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;
  // outline: 0;
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  z-index: 11003;
}

.custom-css-class .k-dialog {
  background-color: $warning; // #333;
  color: #fff;
  font-size: 1.5rem;
}
